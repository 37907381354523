import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import HomeStyles from '../styles/home';
import ComponentFormatos from '../components/Formatos';
import ComponentContato from '../components/Contato';

type listaProduto = {
  label: string,
  value: string,
  url: string,
}

const listaProdutos = [
  {
    label: 'Cobre-Cromo',
    value: 'Liga composta por Cobre e Cromo com alta condutibilidade elétrica e térmica, moderada dureza e de fácil usinagem.',
    url: '/produtos/cobre-cromo'
  },
  {
    label: 'Cobre-Cromo-Zircônio',
    value: 'Liga composta por Cobre, Cromo e Zircônio com alta condutibilidade elétrica e térmica, moderada dureza e de fácil usinagem.',
    url: '/produtos/cobre-cromo-zirconio'
  },
  {
    label: 'Cobre-Cobalto-Berílio',
    value: 'Liga de Cobre, Cobalto e Berílio de alta dureza e elevada condutibilidade térmica para solda por projeção, injeção plástica e injeção metálica.',
    url: '/produtos/cobre-cobalto-berilio'
  },
];

const Home = () => (
  <HomeStyles>
    <HomeStyles.Hero>
      <HomeStyles.Hero.Heading>Ligas especiais de cobre para diversas aplicações</HomeStyles.Hero.Heading>
    </HomeStyles.Hero>

    <HomeStyles.Intro>
      <p>A Metalmax fabrica e comercializa Ligas Especiais de Cobre destinadas às mais diversas aplicações como processos de solda, injeção plástica, injeção metálica, estampagem, laminação, fundição, entre outros.</p>
      <p>Cada liga é desenvolvida especificamente para a aplicação à que será submetida, e, por isso, traz elevados ganhos de produtividade, qualidade e durabilidade quando comparado a outros materiais.</p>
    </HomeStyles.Intro>

    <hr className="metalmaxDivider" />

    <div className="metalmaxTextBlock">
      <div className="metalmaxTextBlockTitle">
        <h2>Qualidade</h2>
      </div>

      <div className="metalmaxTextBlockContent">
        <HomeStyles.Quality>
          <div className="text">
            <p>Desde 2008 a Metalmax Ligas Especiais implementou a norma ISO9001 em todos os seus processos produtivos, por isso todos os produtos por ela comercializados são produzidos de acordo com os rígidos critérios da ISO.</p>
            <ul className="metalmaxFeaturesList">
              <li>
                <div className="icon">
                  <Image
                    src="/media/sgs-iso.png"
                    width={64}
                    height={64}
                    alt="Certificado ISO 9001 desde 2008"
                  />
                </div>
                Certificado ISO 9001 desde 2008
              </li>

              <li>
                <div className="icon">
                  <Image
                    src="/media/icon-calendar.png"
                    width={64}
                    height={64}
                    alt="Prazo de entrega garantido"
                  />
                </div>
                Prazo de entrega garantido
              </li>
            </ul>
          </div>
        </HomeStyles.Quality>
      </div>
    </div>

    <hr className="metalmaxDivider" />

    <div className="metalmaxTextBlock">
      <div className="metalmaxTextBlockTitle">
        <h2>Produtos</h2>
      </div>

      <div className="metalmaxTextBlockContent">
        <p>A Metalmax fabrica e comercializa Ligas Especiais de Cobre destinadas às mais diversas aplicações. Cada liga é desenvolvida especificamente para a aplicação à que é submetida, e, portanto, apresenta determinadas características mecânicas otimizadas de acordo com a necessidade do processo.</p>
        <p>A Metalmax oferece peças em formatos de peças pré-usinadas e também produz peças customizadas conforme desenho técnico. A utilização das Ligas Especiais de Cobre traz elevados ganhos de produtividade, qualidade e durabilidade quando comparado a outros materiais.</p>

        {/* <p>A Metalmax fabrica e comercializa Ligas Especiais de Cobre destinadas às mais diversas aplicações como processos de solda, injeção plástica, injeção metálica, estampagem, laminação, fundição, entre outros.</p>
        <p>Nesses processos, as Ligas Especiais de Cobre são empregadas em diferentes pontos como, por exemplo, eletrodos em seus mais diversos formatos, suportes para eletrodos, enxertos para matrizes em processos de injeção plástica, pistões para processo de injeção metálica, matrizes para fundição de metais não-ferrosos, engrenagens, rolos conformadores, réguas e chapas de desgaste e diversas outras peças.</p>
        <p>A utilização das Ligas Especiais de Cobre traz elevados ganhos de produtividade, qualidade e durabilidade quando comparado a outros materiais.</p>
        <p>Cada uma das ligas é desenvolvida especificamente para a aplicação à que é submetida, e, portanto, apresenta determinadas características mecânicas otimizadas de acordo com a necessidade do processo.</p> */}
      </div>
    </div>

    <div className="metalmaxTextBlock">
      <div className="metalmaxTextBlockTitle">
        <h3>Ligas especiais</h3>
      </div>

      <div className="metalmaxTextBlockContent">
        <HomeStyles.List>
          {(listaProdutos.map(({ label, value, url }: listaProduto) => (
            <li key={url}>
              <HomeStyles.List.Item
                as={Link}
                href={url}
                passHref
              >
                <h4>{label}</h4>
                <p>{value}</p>
                <span className="more">ver mais &rarr;</span>
              </HomeStyles.List.Item>
            </li>
          )))}
        </HomeStyles.List>

        <Link
          href="/produtos"
          passHref
          className="metalmaxButton"
        >
          Conheça todas as ligas
        </Link>
      </div>
    </div>

    <ComponentFormatos />

    <div className="metalmaxTextBlock">
      <div className="metalmaxTextBlockTitle">
        <h3>Aplicações</h3>
      </div>

      <div className="metalmaxTextBlockContent">
        <ul>
          <li>Processos de solda</li>
          <li>Injeção plástica</li>
          <li>Injeção metálica</li>
          <li>Estampagem</li>
          <li>Laminação</li>
          <li>Fundição</li>
          <li>Outras aplicações</li>
        </ul>
      </div>
    </div>

    <hr className="metalmaxDivider" />

    <ComponentContato />
  </HomeStyles>
);

export default Home;