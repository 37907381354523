import React from 'react';

const CustomIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64">
    <polygon points="4,4 4,60 60,60" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <polygon points="12,25 39,52 12,52" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <line x1="12" x2="10" y1="12" y2="14" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />
    <line x1="17" x2="15" y1="17" y2="19" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />
    <line x1="22" x2="20" y1="22" y2="24" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />
    <line x1="27" x2="25" y1="27" y2="29" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />
    <line x1="32" x2="30" y1="32" y2="34" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />
    <line x1="37" x2="35" y1="37" y2="39" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />
    <line x1="42" x2="40" y1="42" y2="44" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />
    <line x1="47" x2="45" y1="47" y2="49" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />
    <line x1="52" x2="50" y1="52" y2="54" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />

    {/* <polygon points="25,15 30,20 35,15 30,10" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" /> */}
    <line x1="25" x2="55" y1="10" y2="40" fill="none" stroke="#333" strokeWidth="2px" strokeLinecap="round" />
    <polygon points="20,10 55,45 60,45 60,40 25,5" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
  </svg>
);

export default CustomIcon;