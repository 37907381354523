import React from 'react';
import Link from 'next/link';
// import Image from 'next/image';
import RodsIcon from '../Icons/RodsIcon';
import RotorsIcon from '../Icons/RotorsIcon';
import BlocksIcon from '../Icons/BlocksIcon';
import CustomIcon from '../Icons/CustomIcon';
import BilletsIcon from '../Icons/BilletsIcon';
import PistonsIcon from '../Icons/PistonsIcon';
import BushingsIcon from '../Icons/BushingsIcon';
import ElectrodesIcon from '../Icons/ElectrodesIcon';

const ComponentFormatos = ({ link }: { link?: boolean }) => (
  <div className="metalmaxTextBlock">
    <div className="metalmaxTextBlockTitle">
      <h3>Formatos</h3>
    </div>

    <div className="metalmaxTextBlockContent">
      {/* <p>A Metalmax oferece diversos formatos de peças pré-usinadas para projetos rápidos, além de produzir peças customizadas conforme desenho técnico. Alguns formatos de peças disponíveis em pré-usinagem são:</p> */}

      <ul className="metalmaxFeaturesList three-columns">
        <li>
          <div className="icon">
            <RodsIcon />
          </div>
          Barras
        </li>
        <li>
          <div className="icon">
            <BlocksIcon />
          </div>
          Blocos
        </li>
        <li>
          <div className="icon">
            <BushingsIcon />
          </div>
          Buchas
        </li>
        <li>
          <div className="icon">
            <RotorsIcon />
          </div>
          Discos
        </li>
        <li>
          <div className="icon">
            <ElectrodesIcon />
          </div>
          Eletrodos
        </li>
        <li>
          <div className="icon">
            <PistonsIcon />
          </div>
          Pistões
        </li>
        <li>
          <div className="icon">
            <BilletsIcon />
          </div>
          Tarugos
        </li>
        <li className='two-thirds'>
          <div className="icon">
            <CustomIcon />
          </div>
          Peças conforme desenho técnico
        </li>
      </ul>

      {(link) && (
        <Link
          href="/produtos"
          passHref
          className="metalmaxButton"
        >
          Conheça as ligas metálicas
        </Link>
      )}
    </div>
  </div>
);

export default ComponentFormatos;