import React from 'react';

const BlocksIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64">
    <polygon points="8,22 32,38 32,58 8,42" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <polygon points="56,22 32,38 32,58 56,42" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <polygon points="8,22 32,38 56,22 32,6" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
  </svg>
);

export default BlocksIcon;