import React from 'react';

const RodsIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64">
    <ellipse cx="18" cy="14" rx="14" ry="8" fill="none" stroke="#333" strokeWidth="2px" />
    <polygon points="32,26 46,33 60,26 46,19" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <polygon points="14,42 28,35 50,46 36,53" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <line x1="4" x2="4" y1="14" y2="58" fill="none" stroke="#333" strokeWidth="2px" />
    <line x1="32" x2="32" y1="14" y2="36" fill="none" stroke="#333" strokeWidth="2px" />
    <line x1="60" x2="60" y1="26" y2="58" fill="none" stroke="#333" strokeWidth="2px" />
    <line x1="14" x2="14" y1="42" y2="60" fill="none" stroke="#333" strokeWidth="2px" />
    <line x1="46" x2="46" y1="33" y2="44" fill="none" stroke="#333" strokeWidth="2px" />
    <line x1="50" x2="50" y1="46" y2="60" fill="none" stroke="#333" strokeWidth="2px" />
    <line x1="36" x2="36" y1="53" y2="64" fill="none" stroke="#333" strokeWidth="2px" />
  </svg>
);

export default RodsIcon;