import styled from 'styled-components';

const HomeStyles: any = styled.div`
  margin: 0 0 80px;
  padding: 0;
`;

HomeStyles.Hero = styled.div`
  height: 60vh;
  max-height: 600px;
  padding: 40px;
  box-sizing: border-box;
  background: #333 url('/media/background.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

HomeStyles.Hero.Heading = styled.p`
  margin: 0 auto 0 0;
  width: 100%;
  max-width: 400px;
  font-size: clamp(2.4rem, 10vw, 3rem);
  line-height: clamp(2.4rem, 10vw, 3rem);
  text-align: left !important;
  color: white;
  text-shadow: 0 1px 2px rgba(0,0,0,0.6);
`;

HomeStyles.Intro = styled.div`
  padding: 40px 40px 0;
  columns: 2;
  column-gap: 40px;

  p {
    margin: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    columns: 1;
  }
`;

HomeStyles.Quality = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .text {
    width: 100%;
    flex: 0 1 auto;
  }

  .figure {
    width: 150px;
    height: 142px;
    flex: 0 0 auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    flex-direction: column;

    .figure {
      margin: 0 auto;
    }
  }
`;

HomeStyles.List = styled.ul`
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  gap: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    grid-template: auto / repeat(2, 1fr);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    grid-template: auto / repeat(1, 1fr);
  }
`;

HomeStyles.List.Item = styled.a`
  background: #EEE;
  border-radius: 4px;
  padding: 20px;
  transition: all 0.2s ease-in-out;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  color: #333;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:hover {
    background: #DDD;
  }

  h4 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
  }

  .more {
    padding-top: 6px;
    display: block;
    margin-top: auto;
    text-align: right;
    color: #00F;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;

export default HomeStyles;