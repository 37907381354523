import React from 'react';

const BushingsIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64">
    <ellipse cx="32" cy="16" rx="22" ry="14" fill="none" stroke="#333" strokeWidth="2px" />
    <ellipse cx="32" cy="16" rx="16" ry="10" fill="none" stroke="#333" strokeWidth="2px" />
    <path d="M 10 16 v 14 c 0 8 10 14 22 14 s 22 -6 22 -14 v -14" fill="none" stroke="#333" strokeWidth="2px" />
    <path d="M 10 22 l -8 4 v 14 l 30 15 l 30 -15 v -14 l -8 -4" fill="none" stroke="#333" strokeWidth="2px" />
    <path d="M 2 40 v 8 l 30 15 l 30 -15 v -8" fill="none" stroke="#333" strokeWidth="2px" />
  </svg>
);

export default BushingsIcon;