import React from 'react';

const ElectrodesIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64">
    <path d="M 12 6 l -3 30 v 24 h 10 v -24 l -3 -30 z" fill="none" stroke="#333" strokeWidth="2px" />
    <path d="M 29 6 l -3 30 v 24 h 10 v -24 l -3 -30 z" fill="none" stroke="#333" strokeWidth="2px" />
    <path d="M 46 6 l -3 30 v 24 h 10 v -24 l -3 -30 z" fill="none" stroke="#333" strokeWidth="2px" />
  </svg>
);

export default ElectrodesIcon;