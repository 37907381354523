import React from 'react';

const PistonsIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64">
    <rect x="6" y="36" width="20" height="26" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <circle cx="16" cy="12" r="10" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <circle cx="16" cy="12" r="6" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <line x1="10" x2="10" y1="20" y2="36" fill="none" stroke="#333" strokeWidth="2px" />
    <line x1="22" x2="22" y1="20" y2="36" fill="none" stroke="#333" strokeWidth="2px" />

    <rect x="38" y="2" width="20" height="26" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <circle cx="48" cy="52" r="10" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <circle cx="48" cy="52" r="6" fill="none" stroke="#333" strokeWidth="2px" strokeLinejoin="round" />
    <line x1="42" x2="42" y1="28" y2="44" fill="none" stroke="#333" strokeWidth="2px" />
    <line x1="54" x2="54" y1="28" y2="44" fill="none" stroke="#333" strokeWidth="2px" />
  </svg>
);

export default PistonsIcon;