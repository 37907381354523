import React from 'react';
import Link from 'next/link';

const ComponentContato = () => (
  <div className="metalmaxTextBlock">
    <div className="metalmaxTextBlockTitle">
      <h2>Contato</h2>
    </div>

    <div className="metalmaxTextBlockContent">
      <p>Precisa de mais informações? Estamos aqui para ajudá-lo com seus projetos e demanda de peças. Entre em contato conosco por telefone ou e-mail.</p>

      <Link
        href="/contato"
        className="metalmaxButton"
        passHref
      >
        Entre em contato
      </Link>
    </div>
  </div>
);

export default ComponentContato;
