import React from 'react';

const RotorsIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64">
    <circle cx="32" cy="32" r="28" fill="none" stroke="#333" strokeWidth="2px" />
    <circle cx="32" cy="32" r="14" fill="none" stroke="#333" strokeWidth="2px" />
    <circle cx="32" cy="12" r="2" fill="none" stroke="#333" strokeWidth="2px" />
    <circle cx="32" cy="52" r="2" fill="none" stroke="#333" strokeWidth="2px" />
    <circle cx="14" cy="42" r="2" fill="none" stroke="#333" strokeWidth="2px" />
    <circle cx="50" cy="42" r="2" fill="none" stroke="#333" strokeWidth="2px" />
    <circle cx="14" cy="22" r="2" fill="none" stroke="#333" strokeWidth="2px" />
    <circle cx="50" cy="22" r="2" fill="none" stroke="#333" strokeWidth="2px" />
  </svg>
);

export default RotorsIcon;